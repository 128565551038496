import { styled } from '@mui/system'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Link from '../../molecules/link'
import Accordion from '../../molecules/accordion'
import Typography from '../../atoms/typography'

const Wrapper = styled('div')`
	flex-grow: 1;
	order: 2;
	border: none;
	padding: ${({ theme }) => theme.spacing(2, 3)};
	${({ theme }) => theme.breakpoints.up('md')} {
		display: flex;
		order: 1;
		padding: 0;
		.MuiLink-root {
			.MuiBox-root {
				display: flex;
				align-items: center;
				gap: 10px;
				.MuiSvgIcon-root {
					font-size: ${({ theme }) => theme.typography.pxToRem(18)};
				}
			}
		}
	}

	& .BhFooterMenuTitle {
		color: ${({ theme }) => theme.palette.footer.color};
	}

	& .MuiPaper-root {
		background: none;
		border: none;
		&:first-of-type {
			border: 0;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			display: flex;
			flex-grow: 1;
			flex-direction: column;
			border: none;
		}
		& .MuiAccordionSummary-root {
			min-height: auto;
			padding: ${({ theme }) => theme.spacing(1, 2, 1, 0)};
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: ${({ theme }) => theme.spacing(0)};
				margin-bottom: var(--layout-line-height-small);
			}
		}
		& .MuiAccordionSummary-expandIconWrapper {
			color: ${({ theme }) => theme.palette.grey.medium};
			${({ theme }) => theme.breakpoints.up('md')} {
				display: none;
			}
		}
		& .MuiAccordionDetails-root {
			padding: ${({ theme }) => theme.spacing(2)};
			${({ theme }) => theme.breakpoints.up('md')} {
				padding: ${({ theme }) => theme.spacing(0)};
			}
		}
		& .MuiAccordionSummary-content {
			color: ${({ theme }) => theme.palette.footer.color};
			.MuiTypography-root {
				line-height: var(----layout-line-height-small);
				font-size: ${({ theme }) => theme.typography.pxToRem(18)};
				font-weight: bold;
				font-family: ${({ theme }) => theme.typography.fontFamily};
				${({ theme }) => theme.breakpoints.up('md')} {
					font-size: ${({ theme }) => theme.typography.pxToRem(12)};
				}
			}
		}
	}
`

const ListStyled = styled(List)`
	display: flex;
	flex-direction: column;
`

const ListItemStyled = styled(ListItem)`
	flex-direction: column;
	align-items: flex-start;
`

const NavLink = styled(Link)`
	color: ${({ theme }) => theme.palette.primary.main};
	line-height: var(--layout-line-height-big);
	padding-bottom: 0;
	font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: 4px;
	}
`

const AccordionStyled = styled(Accordion)`
	${({ theme }) => theme.breakpoints.up('md')} {
		& .MuiCollapse-root {
			transition-duration: 0ms !important;
		}
		& .MuiAccordionSummary-content {
			cursor: text;
		}
	}
`

const TypographyStyled = styled(Typography)`
	color: ${({ theme }) => theme.palette.white.main};
	display: flex;
	flex-grow: 1;
	padding: ${({ theme }) => theme.spacing(2.5)};
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}
`

export { Wrapper, ListStyled, ListItemStyled, NavLink, AccordionStyled, TypographyStyled }
